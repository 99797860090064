/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }

body { margin: 0;}

a {
    text-decoration: none;
}

.justify-space-between{
    display: flex;
    justify-content: space-between;
}

.flex-wrap{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.viz-main-content_menu-toggle {
    display: none !important;
}
